.landing-lightbox.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.landing-lightbox .modal-dialog .modal-content {
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.landing-lightbox .modal-dialog .modal-header {
  border-bottom: none;
}

.landing-lightbox .modal-dialog .modal-body {
  display: flex;
  justify-content: center;
}
.landing-lightbox .modal-dialog .modal-body .left {
  display: none;
  margin-left: -24px;
  margin-right: 20px;
  margin-bottom: -28px;
  margin-top: -90px;
  max-width: 502px;
}
.landing-lightbox .modal-dialog .modal-body .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-lightbox .modal-dialog .modal-body .right h2 {
  text-align: center;
  font-weight: 200;
  font-size: 1.5rem;
}

.landing-lightbox .modal-dialog .modal-body .right h2 .highlight {
  color: #4673ff;
  font-weight: 400;
}

.landing-lightbox .modal-dialog .modal-body .right .btn {
  padding: 0.5rem;
  font-size: 0.8rem;
  margin-top: 10px;
}

.landing-lightbox .modal-dialog .modal-body .right .btn.btn-secondary {
  background: rgba(181, 199, 255, 0.47);
  color: #000;
  border: none;
}

@media (min-width: 992px) {
  .landing-lightbox .modal-dialog {
    max-width: 900px;
  }
  .landing-lightbox .modal-dialog .modal-body .left {
    display: block;
  }
  .landing-lightbox .modal-dialog .modal-header .modal-title {
    margin-left: 500px;
  }
}
