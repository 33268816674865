.application-landing .highlight {
    color: #4673ff;
    font-weight: 600;
}

.application-landing .step-image {
    max-height: 6em;
    margin-bottom: 1em;
}

.application-landing .step-text {
    text-align: center;
    font-weight: 300;
    margin: 0 1em;
}

.application-landing .step-bold {
    font-weight: 600;
}
